import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice, ProjectsGrid } from 'components';
import './page.scss';

const IS_BROWSER = typeof window !== 'undefined';

export const Page = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { page } = data;
  const { data: pageData } = page;

  const landingPageMode = process.env.LANDING_PAGE;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
    hide_footer_links: hideFooterLinks,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.localFile && ogImage.localFile.childImageSharp.fixed.src,
    schema: schema.text,
  };

  return (
    <Layout location={location} seo={seo} hideFooterLinks={hideFooterLinks} className="page">
      {sliceData.map((slice, index) => (
        <Slice key={slice.id} data={slice} sliceIndex={index} landingPageMode={landingPageMode} location={location} />
      ))}
      {location.pathname.includes('kitchen-design') && <ProjectsGrid isOtherProjects title="Kitchen Design Projects" />}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        hide_footer_links
        body {
          ...OneColPageFragment
          ...TwoColPageFragment
          ...TwoColsTextPageFragment
          ...CarouselPageFragment
          ...FormContactPageFragment
          ...TwoColsImagePageFragment
          ...VideoPageFragment
          ...TestimonialPageFragment
          ...WysiwygPageFragment
          ...LocationsPageFragment
          ...GridPageFragment
        }
      }
    }
  }
`;
