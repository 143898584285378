import React from 'react';
import { graphql } from 'gatsby';
import OnVisible from 'react-on-visible';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice, ProjectsGrid } from 'components';
import './project.scss';

const IS_BROWSER = typeof window !== 'undefined';

export const Project = ({ location, data: staticData, ...other }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { project } = data;
  const { data: pageData, uid } = project;

  const landingPageMode = process.env.LANDING_PAGE;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.localFile && ogImage.localFile.childImageSharp.fixed.src,
    schema: schema.text,
  };

  return (
    <Layout location={location} seo={seo} landingPageMode={landingPageMode}>
      <OnVisible className="project">
        {sliceData.map((slice, index) => (
          <Slice key={slice.id} data={slice} sliceIndex={index} landingPageMode={landingPageMode} />
        ))}
        <ProjectsGrid currentProject={uid} title="More projects" />
      </OnVisible>
    </Layout>
  );
};

export default Project;

export const projectQuery = graphql`
  query ProjectBySlug($uid: String!) {
    project: prismicProject(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        hero_image {
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        project_title {
          text
          html
        }
        body {
          ...CarouselProjectFragment
          ...WysiwygProjectFragment
          ...ImageProjectFragment
          ...TwoColsTextProjectFragment
          ...OneColProjectFragment
          ...TwoColProjectFragment
        }
      }
    }
  }
`;
